import React from 'react';
import {Col, Form, Input, Row} from 'antd';
import {LinkOutlined} from '@ant-design/icons';

const OtherInfo = () => {
  return (
    <div className="form-section">
      <h3>Thông tin khác</h3>
      <Row gutter={{
        xs: 8,
        sm: 16,
        md: 24,
      }}>
        <Col xs={{span: 24}} md={{span: 12}}>
          <Form.Item
            label="Tiêu đề trang web"
            name="title"
          >
            <Input/>
          </Form.Item>
          <Form.Item
            label="Meta description"
            name="meta_description"
          >
            <Input.TextArea rows={4}/>
          </Form.Item>
          <Form.Item
            label="Intro"
            name="intro"
          >
            <Input.TextArea rows={5}/>
          </Form.Item>
        </Col>
        <Col xs={{span: 24}} md={{span: 12}}>
          <Form.Item name={['links', 'booking']} label="Booking.com link">
            <Input prefix={<LinkOutlined/>}/>
          </Form.Item>
          <Form.Item name={['links', 'airbnb']} label="Airbnb link">
            <Input prefix={<LinkOutlined/>}/>
          </Form.Item>
          <Form.Item name={['links', 'agoda']} label="Agoda link">
            <Input prefix={<LinkOutlined/>}/>
          </Form.Item>
          <Form.Item
            label="Policy"
            name="policy"
          >
            <Input.TextArea rows={5}/>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default React.memo(OtherInfo);
