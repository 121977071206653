export const GET_AMENITIES_AC = 'GET_AMENITIES_AC';
export const GET_AMENITIES_SUCCESS_AC = 'GET_AMENITIES_SUCCESS_AC';

export const getAmenitiesAC = (language_code = null) => ({
  type: GET_AMENITIES_AC,
  language_code,
});

export const getAmenitiesSuccessAC = (data) => ({
  type: GET_AMENITIES_SUCCESS_AC,
  data,
});
