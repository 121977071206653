import React from 'react';
import {Col, Form, Input, Row} from 'antd';
import UploadPicture from '../../../common/components/Form/UploadPicture';

const BasicInfo = () => {
  return (
    <div className="form-section">
      <h3>Thông tin cơ bản</h3>
      <Row gutter={{
        xs: 8,
        sm: 16,
        md: 24,
      }}>
        <Col xs={{span: 24}} md={{span: 12}}>
          <Form.Item
            label="Tên danh mục"
            name="name"
            rules={[{
              required: true,
              message: 'Vui lòng nhập tên danh mục!'
            }]}
          >
            <Input/>
          </Form.Item>
          <Form.Item
            name="media"
            label="Avatar"
          >
            <UploadPicture
              multiple={false}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default React.memo(BasicInfo);
