import {deleteMediaAPI, uploadMediaAPI} from '../../api';

export const getMediaChanges = (oldValue, newValue) => {
  const result = {
    newMedia: [],
    deletedMedia: [],
  };
  const oldMedia = oldValue || [];
  const newMedia = newValue || [];
  newMedia.forEach(item => {
    if (item) {
      if (!item.id) {
        result.newMedia.push(item);
      }
    }
  });
  oldMedia.forEach(item => {
    if (!newMedia.find(i => i?.id === item.id)) {
      result.deletedMedia.push(item);
    }
  });
  return result;
};

export const updateMedia = async (oldValue, newValue, entityType, entityId) => {
  let errorCount = 0, successCount = 0;
  const {newMedia, deletedMedia} = getMediaChanges(oldValue, newValue);
  if (newMedia.length) {
    for (let i = 0; i < newMedia.length; i++) {
      try {
        await uploadMediaAPI(newMedia[i], entityType, entityId, newMedia[i]?.use_type);
        successCount++;
      } catch (e) {
        errorCount++;
      }
    }
  }
  if (deletedMedia.length) {
    for (let i = 0; i < deletedMedia.length; i++) {
      try {
        await deleteMediaAPI(deletedMedia[i].id);
        successCount++;
      } catch (e) {
        errorCount++;
      }
    }
  }
  return {
    errorCount,
    successCount,
  };
};
