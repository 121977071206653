import React, {Fragment} from 'react';
import {Breadcrumb, Button, Form, message, Skeleton} from 'antd';
import {CloseOutlined, SaveOutlined} from '@ant-design/icons';
import {getErrorMessage, updateMedia} from '../../../common/helpers';
import BasicInfo from './BasicInfo';
import {history} from '../../../history';
import {createCityAPI, getCityAPI, updateCityAPI} from '../../../api/homes';

class UpsertCity extends React.PureComponent {
  state = {
    isSubmitting: false,
    isLoaded: false,
    initialValues: null,
    id: null,
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.handleGetDataAsync().then(state => {
      this.setState({
        ...state,
        isLoaded: true,
      });
    }).catch(error => {
      message.error(getErrorMessage(error));
    });
  };

  handleGetDataAsync = async () => {
    const {match: {params: {id}}} = this.props;
    let category = null;
    if (id) {
      const {data} = await getCityAPI(id);
      category = data;
    } else {
      category = {
        name: '',
        alias: '',
      };
    }
    return {
      id,
      initialValues: category,
      category,
    };
  };

  handleSubmit = (values) => {
    this.setState({
      isSubmitting: true,
    });
    this.handleSubmitAsync(values).then(() => {
      this.setState({
        isSubmitting: false,
      });
      message.success('Đã lưu');
      history.push('/cities');
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isSubmitting: false,
      });
    });
  };

  handleSubmitAsync = async (values) => {
    const {id, initialValues} = this.state;
    const postData = {
      ...initialValues,
      ...values,
    };
    delete postData.id;
    delete postData.media;
    if (id) {
      await updateCityAPI(id, postData);
      await updateMedia(initialValues.media, values.media, 'city', id);
    } else {
      const {data: {id}} = await createCityAPI(postData);
      await updateMedia(initialValues.media, values.media, 'city', id);
    }
  };

  onFinishFailed = () => {
    message.error('Vui lòng kiểm tra lại các thông tin!');
  };

  handleCancel = () => {
    history.goBack();
  };

  renderContent = () => {
    const {isLoaded, initialValues, isSubmitting, categories} = this.state;
    if (!isLoaded) {
      return <Skeleton/>;
    }
    return (
      <Form
        initialValues={initialValues}
        onFinish={this.handleSubmit}
        onFinishFailed={this.onFinishFailed}
        className="common-form"
      >
        <BasicInfo categories={categories}/>
        <div className="form-actions">
          <div className="btn-group">
            <Button
              type="primary"
              htmlType="submit"
              className="submit-button"
              icon={<SaveOutlined/>}
              loading={isSubmitting}
            >
              Lưu
            </Button>
            <Button
              type="default"
              htmlType="button"
              icon={<CloseOutlined/>}
              onClick={this.handleCancel}
            >
              Hủy bỏ
            </Button>
          </div>
        </div>
      </Form>
    );
  };

  render() {
    const {match: {params: {id}}} = this.props;
    return (
      <Fragment>
        <Breadcrumb className="main-breadcrumb">
          <Breadcrumb.Item>Thành phố</Breadcrumb.Item>
          <Breadcrumb.Item>{!!id ? 'Chỉnh sửa' : 'Thêm thành phố'}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="main-content">
          {this.renderContent()}
        </div>
      </Fragment>
    );
  }
}

export default UpsertCity;
