import {put, takeEvery} from 'redux-saga/effects';
import {history} from '../../../history';
import {getCurrentUserAC, LOGIN_SUCCESS_AC, LOGOUT_AC, logoutSuccessAC} from '../../actions/user';
import {getQueryParams} from '../../../common/helpers';
import {message} from 'antd';

function* loginSuccessFlow() {
  const params = getQueryParams();
  yield put(getCurrentUserAC());
  if (params.redirectTo) {
    history.push(params.redirectTo);
    return;
  }
  history.push('/');
}

function* logoutFlow(action) {
  yield put(logoutSuccessAC());
  if (action.isSessionExpired) {
    message.error('Phiên làm việc hết hạn. Vui lòng đăng nhập lại!');
  }
  history.push('/login');
}

export default function* () {
  yield takeEvery(LOGIN_SUCCESS_AC, loginSuccessFlow);
  yield takeEvery(LOGOUT_AC, logoutFlow);
}
