import Requester from '../common/network/Requester';

const API_URL = `${process.env.REACT_APP_API_URL}`;

export const uploadMediaAPI = (file, entityType, entityId, useType = 'avatar') => {
  const formData = new FormData();
  if (file) {
    formData.append('file', file);
  }
  formData.append('entity_type', entityType);
  formData.append('entity_id', entityId);
  formData.append('use_type', useType);
  return Requester.post(`${API_URL}/media`, formData, {
    headers: {'Content-Type': 'multipart/form-data'},
  });
};

export const deleteMediaAPI = (id) => {
  return Requester.delete(`${API_URL}/media/${id}`);
};
