import Requester from '../common/network/Requester';

const API_URL = `${process.env.REACT_APP_API_URL}`;

export const getCitiesAPI = (params = null) => {
  return Requester.get(`${API_URL}/cities`, params);
};

export const getCityAPI = (id, params = null) => {
  return Requester.get(`${API_URL}/cities/${id}`, params);
};

export const createCityAPI = (data) => {
  return Requester.post(`${API_URL}/cities`, data);
};

export const updateCityAPI = (id, data) => {
  return Requester.put(`${API_URL}/cities/${id}`, data);
};

export const deleteCityAPI = (id,) => {
  return Requester.delete(`${API_URL}/cities/${id}`);
};

export const getHomesAPI = (params = null) => {
  return Requester.get(`${API_URL}/homes`, params);
};

export const getHomeAPI = (id, params = null) => {
  return Requester.get(`${API_URL}/homes/${id}`, params);
};

export const createHomeAPI = (data) => {
  return Requester.post(`${API_URL}/homes`, data);
};

export const updateHomeAPI = (id, data) => {
  return Requester.put(`${API_URL}/homes/${id}`, data);
};

export const deleteHomeAPI = (id,) => {
  return Requester.delete(`${API_URL}/homes/${id}`);
};

export const getRoomsAPI = (params = null) => {
  return Requester.get(`${API_URL}/rooms`, params);
};

export const getRoomAPI = (id, params = null) => {
  return Requester.get(`${API_URL}/rooms/${id}`, params);
};

export const createRoomAPI = (data) => {
  return Requester.post(`${API_URL}/rooms`, data);
};

export const updateRoomAPI = (id, data) => {
  return Requester.put(`${API_URL}/rooms/${id}`, data);
};

export const deleteRoomAPI = (id,) => {
  return Requester.delete(`${API_URL}/rooms/${id}`);
};

export const getAmenitiesAPI = (params = null) => {
  return Requester.get(`${API_URL}/amenities`, params);
};

export const updateAmenitiesAPI = (homeId, data) => {
  return Requester.put(`${API_URL}/homes/${homeId}/amenities`, data);
};
