import createReducer from '../createReducer';
import {GET_AMENITIES_SUCCESS_AC} from '../../actions/home';

const initialState = {
  list: [],
};

const handler = {
  [GET_AMENITIES_SUCCESS_AC]: (state, action) => ({
    ...state,
    list: action.data,
  }),
};

export default createReducer(initialState, handler);
