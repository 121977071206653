export const LOGIN_SUCCESS_AC = 'LOGIN_SUCCESS_AC';
export const LOGOUT_AC = 'LOGOUT_AC';
export const LOGOUT_SUCCESS_AC = 'LOGOUT_SUCCESS_AC';
export const REFRESH_TOKEN_SUCCESS_AC = 'REFRESH_TOKEN_SUCCESS_AC';

export const loginSuccessAC = (data) => ({
  type: LOGIN_SUCCESS_AC,
  data,
});

export const logoutAC = (isSessionExpired = false) => ({
  type: LOGOUT_AC,
  isSessionExpired,
});

export const logoutSuccessAC = () => ({
  type: LOGOUT_SUCCESS_AC,
});

export const refreshTokenSuccessAC = (data) => ({
  type: REFRESH_TOKEN_SUCCESS_AC,
  data,
});
