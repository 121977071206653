import {all} from 'redux-saga/effects';
import HomesWatcher from './homes';
import UserWatcher from './user';

export default function* rootSaga() {
  yield all([
    ...HomesWatcher,
    ...UserWatcher,
  ])
}
