import Requester from '../common/network/Requester';

const API_URL = `${process.env.REACT_APP_API_URL}`;

export const getArticlesAPI = (params = null) => {
  return Requester.get(`${API_URL}/articles`, params);
};

export const getArticleAPI = (id, params = null) => {
  return Requester.get(`${API_URL}/articles/${id}`, params);
};

export const createArticleAPI = (data) => {
  return Requester.post(`${API_URL}/articles`, data);
};

export const updateArticleAPI = (id, data) => {
  return Requester.put(`${API_URL}/articles/${id}`, data);
};

export const deleteArticleAPI = (id,) => {
  return Requester.delete(`${API_URL}/articles/${id}`);
};

export const getCategoriesAPI = (params = null) => {
  return Requester.get(`${API_URL}/categories`, params);
};

export const getCategoryAPI = (id) => {
  return Requester.get(`${API_URL}/categories/${id}`);
};

export const createCategoryAPI = (data) => {
  return Requester.post(`${API_URL}/categories`, data);
};

export const updateCategoryAPI = (id, data) => {
  return Requester.put(`${API_URL}/categories/${id}`, data);
};

export const deleteCategoryAPI = (id) => {
  return Requester.delete(`${API_URL}/categories/${id}`);
};

export const getSlideshowsAPI = (params = null) => {
  return Requester.get(`${API_URL}/slideshows`, params);
};

export const getSlideshowAPI = (id) => {
  return Requester.get(`${API_URL}/slideshows/${id}`);
};

export const createSlideshowAPI = (data) => {
  return Requester.post(`${API_URL}/slideshows`, data);
};

export const updateSlideshowAPI = (id, data) => {
  return Requester.put(`${API_URL}/slideshows/${id}`, data);
};

export const deleteSlideshowAPI = (id) => {
  return Requester.delete(`${API_URL}/slideshows/${id}`);
};

export const getSlideshowItemsAPI = (params = null) => {
  return Requester.get(`${API_URL}/slideshow-items`, params);
};

export const getSlideshowItemAPI = (id) => {
  return Requester.get(`${API_URL}/slideshow-items/${id}`);
};

export const createSlideshowItemAPI = (data) => {
  return Requester.post(`${API_URL}/slideshow-items`, data);
};

export const updateSlideshowItemAPI = (id, data) => {
  return Requester.put(`${API_URL}/slideshow-items/${id}`, data);
};

export const deleteSlideshowItemAPI = (id) => {
  return Requester.delete(`${API_URL}/slideshow-items/${id}`);
};

