import React from 'react';
import {connect} from 'react-redux';
import {Checkbox} from 'antd';
import './Amenities.scss';

const Amenities = React.memo(function Amenities({value, onChange, amenities}) {
  const options = amenities.filter(item => item.language_code === 'vi');

  const onInputChange = (item) => (event) => {
    const checked = event.target.checked;
    if (checked) {
      const newValues = value ? [...value] : [];
      if (newValues.find(v => v?.id === item.id)) {
        return;
      }
      newValues.push(item);
      onChange(newValues);
    } else {
      const newValues = value ? [...value] : [];
      const index = newValues.findIndex(v => v?.id === item.id);
      if (index >= 0) {
        newValues.splice(index, 1);
        onChange(newValues);
      }
    }
  };

  const isItemChecked = (item) => {
    if (!item || !value?.length) {
      return false;
    }
    return !!value.find(v => v?.id === item.id);
  };

  return (
    <div className="amenities">
      {
        options.map((item, index) => (
          <Checkbox
            value={item.id}
            onChange={onInputChange(item)}
            checked={isItemChecked(item)}
            key={index}
          >
            {item.name}
          </Checkbox>
        ))
      }
    </div>
  );
});

const mapStateToProps = (state) => ({
  amenities: state.homes.amenities.list,
});

export default connect(mapStateToProps)(Amenities);
