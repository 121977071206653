import React from 'react';
import {Switch} from 'react-router-dom';
import {AppRoute, MainLayout} from './layout';
import Login from './pages/auth/Login/Login';
import Dashboard from './pages/dashboard/Dashboard';
import Account from './pages/settings/AccountSettings/Account';
import ResetPassword from './pages/auth/ResetPassword/ResetPassword';
import AuthLayout from './layout/AuthLayout/AuthLayout';
import VerifyEmail from './pages/auth/VerifyEmail/VerifyEmail';
import Error404 from './pages/errors/Error404';
import Articles from './pages/articles/Articles';
import UpsertArticle from './pages/articles/UpsertArticle/UpsertArticle';
import Categories from './pages/categories/Categories';
import UpsertCategory from './pages/categories/UpsertCategory/UpsertCategory';
import Cities from './pages/cities/Cities';
import UpsertCity from './pages/cities/UpsertCity/UpsertCity';
import Homes from './pages/homes/Homes';
import UpsertHome from './pages/homes/UpsertHome/UpsertHome';
import UpsertSlideshowItem from './pages/slideshows/UpsertSlideshowItem';
import SlideshowItems from './pages/slideshows/SlideshowItems';

const Routes = () => (
  <Switch>
    <AppRoute
      path={'/'}
      component={Dashboard}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/account'}
      component={Account}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/login'}
      component={Login}
      layout={AuthLayout}
      exact={true}
    />
    <AppRoute
      path={'/reset-password'}
      component={ResetPassword}
      layout={AuthLayout}
      exact={true}
    />
    <AppRoute
      path={'/verify-email'}
      component={VerifyEmail}
      exact={true}
    />
    <AppRoute
      path={'/articles'}
      component={Articles}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={['/articles/edit/:id', '/articles/new']}
      component={UpsertArticle}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/categories'}
      component={Categories}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={['/categories/edit/:id', '/categories/new']}
      component={UpsertCategory}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/cities'}
      component={Cities}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={['/cities/edit/:id', '/cities/new']}
      component={UpsertCity}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/homes'}
      component={Homes}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={['/homes/edit/:id', '/homes/new']}
      component={UpsertHome}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/slideshows'}
      component={SlideshowItems}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={['/slideshows/:slideshowId/items/:id', '/slideshows/new']}
      component={UpsertSlideshowItem}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      component={Error404}
    />
  </Switch>
);

export default Routes;
