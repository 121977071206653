import {call, put, takeEvery} from 'redux-saga/effects';
import {
  GET_CURRENT_USER_AC,
  getCurrentUserSuccessAC,
  logoutAC
} from '../../actions/user';
import {getCurrentUserAPI} from '../../../api';

function* getCurrentUserFlow(action) {
  try {
    const {data} = yield call(getCurrentUserAPI);
    yield put(getCurrentUserSuccessAC({
      ...data,
    }));
  } catch (e) {
    if (action.isCheckLogin) {
      yield put(logoutAC(true));
    }
  }
}

export default function* () {
  yield takeEvery(GET_CURRENT_USER_AC, getCurrentUserFlow);
}
