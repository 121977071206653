import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {PoweroffOutlined, UserOutlined} from '@ant-design/icons';
import {Dropdown, Menu} from 'antd';
import {Link} from 'react-router-dom';
import HyperLink from '../../common/components/HyperLink/HyperLink';
import UserAvatar from '../../common/components/UserInfo/UserAvatar';

class LoggedInUser extends React.PureComponent {
  render() {
    const {user, handleLogout, isMobile} = this.props;
    if (!user) {
      return null;
    }
    const menu = (
      <Menu>
        <Menu.Item key="0">
          <Link to="/account"><UserOutlined/> Tài khoản</Link>
        </Menu.Item>
        <Menu.Divider/>
        <Menu.Item key="1">
          <HyperLink onClick={handleLogout}><PoweroffOutlined/> Đăng xuất</HyperLink>
        </Menu.Item>
      </Menu>
    );
    return (
      <Fragment>
        <Dropdown overlay={menu} trigger={['click']}>
          <HyperLink className="header-icon">
            <UserAvatar user={user} showName={!isMobile}/>
          </HyperLink>
        </Dropdown>
      </Fragment>
    );
  }
}

LoggedInUser.propTypes = {
  user: PropTypes.any,
  isMobile: PropTypes.bool,
  handleLogout: PropTypes.func,
};

LoggedInUser.defaultProps = {
  showName: true,
};

export default LoggedInUser;
