import React, {Suspense} from 'react';
import {Redirect, Route} from 'react-router-dom';
import PropTypes from 'prop-types';
import Store from '../redux/store/Store';
import {buildQueryString} from '../common/helpers';
import CheckingUser from './CheckingUser';

/**
 * App route with layout
 * @param Layout
 * @param Component
 * @param Fallback
 * @param isPrivate
 * @param others
 * @returns {*}
 * @constructor
 */
const AppRoute = ({layout: Layout, component: Component, fallback: Fallback, isPrivate, ...others}) => (
  <Route {...others} render={props => {
    if (isPrivate) {
      const user = Store.getState().user;
      if (!user.auth.isAuthenticated) {
        // We are getting the token
        if (user.auth.token) {
          return <CheckingUser/>;
        }
        // Otherwise, user haven't logged in yet
        let queryString = '?' + buildQueryString({
          redirectTo: `${props.location.pathname}${props.location.search || ''}`
        });
        if (queryString === '?redirectTo=%2F') {
          queryString = '';
        }
        return <Redirect to={`/login${queryString}`}/>
      }
    }
    const content = function () {
      if (Fallback) {
        return (
          <Suspense fallback={Fallback}>
            <Component {...props} />
          </Suspense>
        )
      }
      return <Component {...props} />;
    }();
    if (!Layout) {
      return content;
    }
    return (
      <Layout {...props}>
        {content}
      </Layout>
    );
  }}/>
);

AppRoute.propTypes = {
  exact: PropTypes.bool,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  component: PropTypes.any.isRequired,
  layout: PropTypes.any,
  fallback: PropTypes.any,
  isPrivate: PropTypes.bool,
};

AppRoute.defaultProps = {
  exact: true,
  layout: null,
  fallback: null,
  isPrivate: false,
};

export default AppRoute;
