import React from 'react';
import {Col, Form, Input, Row, Select} from 'antd';
import {ARTICLE_TYPE} from '../../../common/constants/common';
import RichTextEditor from '../../../common/components/Form/RichTextEditor';
import UploadPicture from '../../../common/components/Form/UploadPicture';

const TypeOptions = Object.values(ARTICLE_TYPE);

const BasicInfo = ({categories}) => {
  return (
    <div className="form-section">
      <h3>Thông tin cơ bản</h3>
      <Row gutter={{
        xs: 8,
        sm: 16,
        md: 24,
      }}>
        <Col xs={{span: 24}} md={{span: 12}}>
          <Form.Item
            label="Tên bài viết"
            name="name"
            rules={[{
              required: true,
              message: 'Vui lòng nhập tên bài viết!'
            }]}
          >
            <Input/>
          </Form.Item>
          <Form.Item
            label="Loại bài viết"
            name="type"
            rules={[{
              required: true,
              message: 'Vui lòng chọn loại bài viết!'
            }]}
            style={{display: 'none'}}
          >
            <Select defaultValue={ARTICLE_TYPE.NEWSLETTER.id}>
              {
                TypeOptions.map((item, index) => (
                  <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                ))
              }
            </Select>
          </Form.Item>
          <Form.Item
            label="Danh mục"
            name="category_id"
            rules={[{
              required: true,
              message: 'Vui lòng chọn danh mục!'
            }]}
          >
            <Select>
              {
                categories.map((item, index) => (
                  <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
        <Col xs={{span: 24}} md={{span: 12}}>
          <Form.Item
            name="media"
            label="Avatar"
          >
            <UploadPicture
              multiple={false}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={{span: 24}} md={{span: 24}}>
          <Form.Item
            label="Nội dung bài viết"
            name="content"
          >
            <RichTextEditor/>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default React.memo(BasicInfo);
