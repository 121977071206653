import React, {Fragment} from 'react';
import {Breadcrumb, Empty, message, Pagination, Popconfirm, Result, Skeleton, Table} from 'antd';
import {Link} from 'react-router-dom';
import HyperLink from '../../common/components/HyperLink/HyperLink';
import {deleteCategoryAPI, getCategoriesAPI} from '../../api';
import {formatDate, getErrorMessage} from '../../common/helpers';
import {DeleteOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';

class Categories extends React.PureComponent {
  state = {
    data: [],
    searchParams: {},
    pagination: {
      page: 1,
      page_size: 10,
      total_items: 0,
    },
    selectedIds: [],
    isLoaded: false,
    isFetching: false,
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.setState({
      isFetching: true,
    });
    this.handleGetData().then(res => {
      this.setState({
        isFetching: false,
        isLoaded: true,
        data: res?.data?.page_data || [],
        pagination: res?.data?.page_info,
        selectedIds: [],
      });
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isFetching: false,
        isLoaded: true,
        data: [],
        selectedIds: [],
      });
    });
  };

  handleGetData = async () => {
    const {type, companyId} = this.props;
    const {pagination, searchParams} = this.state;
    const params = {
      page: pagination.page,
      page_size: pagination.page_size,
      tax_code_id: companyId,
      type,
      ...searchParams,
    };
    let result = null;
    while (params.page >= 1) {
      result = await getCategoriesAPI(params);
      const {data: {page_data, page_info}} = result;
      if (!page_data.length && page_info.page > 1) {
        params.page = page_info.page - 1;
      } else {
        break;
      }
    }
    return result;
  };

  handleDelete = (ids) => () => {
    message.info('Đang xóa...');
    this.handleDeleteAsync(ids).then(() => {
      message.success('Đã xóa.');
      this.getData();
    }).catch(error => {
      message.error(getErrorMessage(error));
    });
  };

  handleDeleteAsync = async (ids) => {
    for (let i = 0; i < ids.length; i++) {
      await deleteCategoryAPI(ids[i]);
    }
  };

  handleTableChange = (page, pageSize) => {
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        page: page,
        page_size: pageSize,
      }
    }), this.getData);
  };

  rowSelection = {
    columnWidth: 40,
    onChange: (selectedRowKeys) => {
      this.setState({
        selectedIds: selectedRowKeys,
      });
    },
  };

  columns = [
    {
      title: 'Avatar',
      dataIndex: 'media',
      width: 80,
      align: 'center',
      render: (value, record) => {
        if (!value?.length || !value?.[0]?.preview_url) {
          return null;
        }
        return (
          <div className="avatar-preview">
            <img src={value[0].preview_url} alt={record.name}/>
          </div>
        );
      },
    },
    {
      title: 'Tên danh mục',
      dataIndex: 'name',
      ellipsis: true,
    },
    {
      title: 'Cập nhật lần cuối',
      dataIndex: 'updated_at',
      align: 'center',
      render: value => <span>{formatDate(value)}</span>,
      width: 150,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      align: 'center',
      render: value => <span>{formatDate(value)}</span>,
      width: 150,
    },
    {
      title: 'Thao tác',
      key: 'actions',
      width: 150,
      fixed: 'right',
      align: 'center',
      render: (value, record) => {
        return (
          <div className="actions text-center">
            <Link
              to={`/categories/edit/${record.id}`}
            >
              <EditOutlined/> Sửa
            </Link>
            <Popconfirm
              title={<div>Bạn có chắc muốn xóa danh mục này không? Toàn bộ bài viết liên quan sẽ bị xóa!!!</div>}
              onConfirm={this.handleDelete([record.id])}
              okText="Xóa"
              cancelText="Hủy bỏ"
              okType="danger"
            >
              <HyperLink>
                <DeleteOutlined/> Xóa
              </HyperLink>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  renderContent = () => {
    const {isLoaded, isFetching, selectedIds, data, pagination} = this.state;
    const isSearch = false;
    if (!isLoaded) {
      return <Skeleton/>;
    }
    if (!data.length) {
      if (!isSearch) {
        return (
          <Result
            status="404"
            title="Chưa có danh mục"
            subTitle="Bạn chưa có danh mục nào. Click vào nút dưới đây để tạo danh mục mới."
            extra={
              <Link
                className="ant-btn ant-btn-primary"
                to={`/categories/new`}
              >
                <PlusOutlined/> Tạo danh mục
              </Link>
            }
          />
        );
      } else {
        return (
          <Empty description="Không có kết quả"/>
        );
      }
    }
    return (
      <Fragment>
        <div className="table-header">
          <div className="action-buttons">
            <Link
              className="ant-btn ant-btn-primary"
              to={`/categories/new`}
            >
              <PlusOutlined/> Thêm mới
            </Link>
          </div>
          <div className="selected-item">
            {
              !!selectedIds.length &&
              <div>
                Đã chọn {selectedIds.length} danh mục.
                <Popconfirm
                  title={<div>Bạn có chắc muốn xóa {selectedIds.length} danh mục này không? Toàn bộ bài viết liên quan
                    sẽ bị xóa!!!</div>}
                  onConfirm={this.handleDelete(selectedIds)}
                  okText="Xóa"
                  cancelText="Hủy bỏ"
                  okType="danger"
                >
                  <HyperLink style={{marginLeft: 10}}>Xóa</HyperLink>
                </Popconfirm>
              </div>
            }
          </div>
        </div>
        <Table
          size="small"
          className="common-table"
          columns={this.columns}
          bordered={true}
          rowKey={record => record.id}
          dataSource={data}
          pagination={false}
          loading={isFetching}
          rowSelection={{
            ...this.rowSelection,
            selectedRowKeys: selectedIds,
          }}
          scroll={{x: 1100}}
        />
        <div className="table-footer">
          <Pagination
            total={pagination.total_items}
            pageSize={pagination.page_size}
            current={pagination.page}
            onChange={this.handleTableChange}
          />
        </div>
      </Fragment>
    );
  };

  render() {
    return (
      <Fragment>
        <Breadcrumb className="main-breadcrumb">
          <Breadcrumb.Item>Danh mục bài viết</Breadcrumb.Item>
        </Breadcrumb>
        <div className="main-content">
          {this.renderContent()}
        </div>
      </Fragment>
    );
  }
}

export default Categories;
