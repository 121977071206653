import {combineReducers} from 'redux';
import app from './app';
import homes from './homes';
import user from './user';
import {LOGOUT_SUCCESS_AC} from '../actions/user';

const appReducer = combineReducers({
  app,
  homes,
  user,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS_AC) {
    state = undefined
  }
  return appReducer(state, action)
};

export default rootReducer;
