import React, {Fragment} from 'react';
import {Breadcrumb, Button, Form, message, Skeleton, Tabs} from 'antd';
import {CloseOutlined, SaveOutlined} from '@ant-design/icons';
import {convertToSlug, getErrorMessage, updateMedia} from '../../../common/helpers';
import BasicInfo from './BasicInfo';
import {history} from '../../../history';
import OtherInfo from './OtherInfo';
import {HOME_TYPE} from '../../../common/constants/common';
import {createHomeAPI, getCitiesAPI, getHomeAPI, updateAmenitiesAPI, updateHomeAPI} from '../../../api/homes';
import store from '../../../redux/store/Store';
import {getAmenitiesAC} from '../../../redux/actions/home';

class UpsertHome extends React.PureComponent {
  state = {
    isSubmitting: false,
    isLoaded: false,
    initialValues: null,
    id: null,
    cities: [],
  };

  componentDidMount() {
    this.getData();
    store.dispatch(getAmenitiesAC('vi'));
  }

  getData = () => {
    this.handleGetDataAsync().then(state => {
      this.setState({
        ...state,
        isLoaded: true,
      });
    }).catch(error => {
      message.error(getErrorMessage(error));
    });
  };

  handleGetDataAsync = async () => {
    const {match: {params: {id}}} = this.props;
    const {data: {page_data: cities}} = await getCitiesAPI({page_size: 100});
    let home = null;
    if (id) {
      const {data} = await getHomeAPI(id);
      home = data;
    } else {
      home = {
        name: '',
        title: '',
        intro: '',
        full_description: '',
        meta_description: '',
        policy: '',
        links: null,
        amenities: null,
        city_id: null,
        lat: null,
        lng: null,
        type: HOME_TYPE.DORM_HOMESTAY.id,
      };
    }
    return {
      id,
      initialValues: home,
      cities,
    };
  };

  handleSubmit = (values) => {
    this.setState({
      isSubmitting: true,
    });
    this.handleSubmitAsync(values).then(() => {
      this.setState({
        isSubmitting: false,
      });
      message.success('Đã lưu');
      history.push('/homes');
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isSubmitting: false,
      });
    });
  };

  handleSubmitAsync = async (values) => {
    const {id, initialValues} = this.state;
    const postData = {
      ...initialValues,
      ...values,
    };
    if (!postData.alias) {
      postData.alias = convertToSlug(postData.name);
    }
    delete postData.id;
    delete postData.city;
    delete postData.media;
    delete postData.amenities;
    const amenityIds = (values.amenities || []).map(item => item.id);
    if (id) {
      await updateHomeAPI(id, postData);
      await updateMedia(initialValues.media, values.media, 'home', id);
      await updateAmenitiesAPI(id, amenityIds);
    } else {
      const {data: {id}} = await createHomeAPI(postData);
      await updateMedia(initialValues.media, values.media, 'home', id);
      await updateAmenitiesAPI(id, amenityIds);
    }
  };

  onFinishFailed = () => {
    message.error('Vui lòng kiểm tra lại các thông tin!');
  };

  handleCancel = () => {
    history.goBack();
  };

  renderContent = () => {
    const {isLoaded, initialValues, isSubmitting, cities} = this.state;
    if (!isLoaded) {
      return <Skeleton/>;
    }
    return (
      <Form
        initialValues={initialValues}
        onFinish={this.handleSubmit}
        onFinishFailed={this.onFinishFailed}
        className="common-form"
      >
        <Tabs defaultActiveKey="basic">
          <Tabs.TabPane tab="Thông tin cơ bản" key="basic">
            <BasicInfo cities={cities}/>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Thông tin khác" key="other">
            <OtherInfo/>
          </Tabs.TabPane>
        </Tabs>
        <div className="form-actions">
          <div className="btn-group">
            <Button
              type="primary"
              htmlType="submit"
              className="submit-button"
              icon={<SaveOutlined/>}
              loading={isSubmitting}
            >
              Lưu
            </Button>
            <Button
              type="default"
              htmlType="button"
              icon={<CloseOutlined/>}
              onClick={this.handleCancel}
            >
              Hủy bỏ
            </Button>
          </div>
        </div>
      </Form>
    );
  };

  render() {
    const {match: {params: {id}}} = this.props;
    return (
      <Fragment>
        <Breadcrumb className="main-breadcrumb">
          <Breadcrumb.Item>Căn hộ</Breadcrumb.Item>
          <Breadcrumb.Item>{!!id ? 'Chỉnh sửa' : 'Thêm'} căn hộ</Breadcrumb.Item>
        </Breadcrumb>
        <div className="main-content">
          {this.renderContent()}
        </div>
      </Fragment>
    );
  }
}

export default UpsertHome;
