import Requester from '../common/network/Requester';
import Axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}`;

export const loginAPI = (data) => {
  return Requester.post(`${API_URL}/auth/login`, data);
};

export const refreshTokenAPI = (data, config = {}) => {
  return Axios.post(`${API_URL}/auth/refresh`, data, config);
};

export const forgotPasswordAPI = (data) => {
  return Requester.post(`${API_URL}/auth/forgot-password`, data);
};

export const resetPasswordAPI = (data) => {
  return Requester.post(`${API_URL}/auth/reset-password`, data);
};

export const verifyEmailAPI = (data) => {
  return Requester.post(`${API_URL}/auth/verify-email`, data);
};
