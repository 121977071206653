import React from 'react';
import {Col, Form, Input, Row} from 'antd';

const OtherInfo = () => {
  return (
    <div className="form-section">
      <h3>Thông tin khác</h3>
      <Row gutter={{
        xs: 8,
        sm: 16,
        md: 24,
      }}>
        <Col xs={{span: 24}} md={{span: 12}}>
          <Form.Item
            label="Tiêu đề trang web"
            name="title"
          >
            <Input/>
          </Form.Item>
          <Form.Item
            label="Meta description"
            name="meta_description"
          >
            <Input.TextArea rows={5}/>
          </Form.Item>
          <Form.Item
            label="Intro"
            name="intro"
          >
            <Input.TextArea rows={5}/>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default React.memo(OtherInfo);
