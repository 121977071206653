import Requester from '../common/network/Requester';

const API_URL = `${process.env.REACT_APP_API_URL}`;

export const getCurrentUserAPI = () => {
  return Requester.get(`${API_URL}/auth/me`);
};

export const updateCurrentUserAPI = (data) => {
  return Requester.put(`${API_URL}/users/me`, data);
};

export const updatePasswordAPI = (data) => {
  return Requester.put(`${API_URL}/users/me/password`, data);
};
