import React, {Fragment} from 'react';
import {createCategoryAPI, getCategoryAPI, updateCategoryAPI} from '../../../api';
import {Breadcrumb, Button, Form, message, Skeleton, Tabs} from 'antd';
import {CloseOutlined, SaveOutlined} from '@ant-design/icons';
import {convertToSlug, getErrorMessage, updateMedia} from '../../../common/helpers';
import BasicInfo from './BasicInfo';
import {history} from '../../../history';
import OtherInfo from './OtherInfo';

class UpsertCategory extends React.PureComponent {
  state = {
    isSubmitting: false,
    isLoaded: false,
    initialValues: null,
    id: null,
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.handleGetDataAsync().then(state => {
      this.setState({
        ...state,
        isLoaded: true,
      });
    }).catch(error => {
      message.error(getErrorMessage(error));
    });
  };

  handleGetDataAsync = async () => {
    const {match: {params: {id}}} = this.props;
    let category = null;
    if (id) {
      const {data} = await getCategoryAPI(id);
      category = data;
    } else {
      category = {
        name: '',
        title: '',
        meta_description: '',
      };
    }
    return {
      id,
      initialValues: category,
      category,
    };
  };

  handleSubmit = (values) => {
    this.setState({
      isSubmitting: true,
    });
    this.handleSubmitAsync(values).then(() => {
      this.setState({
        isSubmitting: false,
      });
      message.success('Đã lưu');
      history.push('/categories');
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isSubmitting: false,
      });
    });
  };

  handleSubmitAsync = async (values) => {
    const {id, initialValues} = this.state;
    const postData = {
      ...initialValues,
      ...values,
    };
    delete postData.id;
    delete postData.media;
    delete postData.avatar;
    if (!postData.alias) {
      postData.alias = convertToSlug(postData.name);
    }
    if (id) {
      await updateCategoryAPI(id, postData);
      await updateMedia(initialValues.media, values.media, 'category', id);
    } else {
      const {data: {id}} = await createCategoryAPI(postData);
      await updateMedia(initialValues.media, values.media, 'category', id);
    }
  };

  onFinishFailed = () => {
    message.error('Vui lòng kiểm tra lại các thông tin!');
  };

  handleCancel = () => {
    history.goBack();
  };

  renderContent = () => {
    const {isLoaded, initialValues, isSubmitting, categories} = this.state;
    if (!isLoaded) {
      return <Skeleton/>;
    }
    return (
      <Form
        initialValues={initialValues}
        onFinish={this.handleSubmit}
        onFinishFailed={this.onFinishFailed}
        className="common-form"
      >
        <Tabs defaultActiveKey="basic">
          <Tabs.TabPane tab="Thông tin cơ bản" key="basic">
            <BasicInfo categories={categories}/>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Thông tin khác" key="other">
            <OtherInfo/>
          </Tabs.TabPane>
        </Tabs>
        <div className="form-actions">
          <div className="btn-group">
            <Button
              type="primary"
              htmlType="submit"
              className="submit-button"
              icon={<SaveOutlined/>}
              loading={isSubmitting}
            >
              Lưu
            </Button>
            <Button
              type="default"
              htmlType="button"
              icon={<CloseOutlined/>}
              onClick={this.handleCancel}
            >
              Hủy bỏ
            </Button>
          </div>
        </div>
      </Form>
    );
  };

  render() {
    const {match: {params: {id}}} = this.props;
    return (
      <Fragment>
        <Breadcrumb className="main-breadcrumb">
          <Breadcrumb.Item>Danh mục bài viết</Breadcrumb.Item>
          <Breadcrumb.Item>{!!id ? 'Chỉnh sửa' : 'Tạo danh mục'}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="main-content">
          {this.renderContent()}
        </div>
      </Fragment>
    );
  }
}

export default UpsertCategory;
