import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {forgotPasswordAPI, loginAPI} from '../../../api/auth';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {Button, Form, Input, message} from 'antd';
import HyperLink from '../../../common/components/HyperLink/HyperLink';
import {loginSuccessAC} from '../../../redux/actions/user';
import {Link} from 'react-router-dom';
import {getErrorMessage} from '../../../common/helpers';

class Login extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      isForgotPassword: false,
    };
  }

  handleSubmit = (values) => {
    if (this.state.isForgotPassword) {
      this.handleForgotPassword(values);
    } else {
      this.handleLogin(values);
    }
  };

  toggleForgotPassword = () => {
    this.setState(prevState => {
      return {
        isForgotPassword: !prevState.isForgotPassword,
      };
    });
  };

  handleForgotPassword = (formData) => {
    this.setState({
      isSubmitting: true,
    });
    forgotPasswordAPI(formData).then(() => {
      this.setState({
        isSubmitting: false,
        isForgotPassword: false,
      });
      message.success('Thành công! Vui lòng kiểm tra email để đặt lại mật khẩu!');
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isSubmitting: false,
      });
    });
  };

  handleLogin = (formData) => {
    this.setState({
      isSubmitting: true,
    });
    loginAPI(formData).then(res => {
      this.setState({
        isSubmitting: false,
      });
      this.props.dispatch(loginSuccessAC({
        ...res.data,
        remember: !!formData.remember,
      }));
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isSubmitting: false,
      });
    });
  };

  render() {
    const {isSubmitting, isForgotPassword} = this.state;
    return (
      <Form
        className="login-form common-form"
        onFinish={this.handleSubmit}
      >
        <Form.Item
          label={isForgotPassword ? 'Tên truy cập hoặc email' : 'Tên truy cập'}
          name="email"
          rules={[{
            required: true,
            message: 'Vui lòng nhập tên truy cập!'
          }]}
        >
          <Input
            prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
            placeholder="Tên truy cập hoặc email"
          />
        </Form.Item>
        {
          !isForgotPassword &&
          <Fragment>
            <Form.Item
              label="Mật khẩu"
              name="password"
              rules={[{
                required: true,
                message: 'Vui lòng nhập mật khẩu!'
              }]}
            >
              <Input
                prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                type="password"
                placeholder="Mật khẩu"
              />
            </Form.Item>
            {/*<Form.Item name="remember" valuePropName="checked">*/}
            {/*<Checkbox>Ghi nhớ đăng nhập</Checkbox>*/}
            {/*</Form.Item>*/}
            <HyperLink className="login-form-forgot" onClick={this.toggleForgotPassword}>
              Quên mật khẩu?
            </HyperLink>
            <Form.Item className="form-actions">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                disabled={isSubmitting}
              >
                Đăng nhập
              </Button>
            </Form.Item>
          </Fragment>
        }
        {
          isForgotPassword &&
          <Fragment>
            <HyperLink onClick={this.toggleForgotPassword}>
              Đăng nhập
            </HyperLink>
            <Form.Item className="form-actions">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                disabled={isSubmitting}
              >
                Lấy mật khẩu
              </Button>
            </Form.Item>
          </Fragment>
        }
      </Form>
    );
  }
}

export default connect()(Login);
