import React from 'react';
import {Col, Form, Input, Row, Select} from 'antd';
import {HOME_TYPE} from '../../../common/constants/common';
import RichTextEditor from '../../../common/components/Form/RichTextEditor';
import UploadPicture from '../../../common/components/Form/UploadPicture';
import Amenities from '../../../common/components/Amenities/Amenities';

const TypeOptions = Object.values(HOME_TYPE);

const BasicInfo = ({cities}) => {
  return (
    <div className="form-section">
      <h3>Thông tin cơ bản</h3>
      <Row gutter={{
        xs: 8,
        sm: 16,
        md: 24,
      }}>
        <Col xs={{span: 24}} md={{span: 12}}>
          <Form.Item
            label="Tên căn hộ"
            name="name"
            rules={[{
              required: true,
              message: 'Vui lòng nhập tên căn hộ!'
            }]}
          >
            <Input/>
          </Form.Item>
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
            }}
          >
            <Col xs={{span: 24}} md={{span: 12}}>
              <Form.Item
                label="Loại căn hộ"
                name="type"
                rules={[{
                  required: true,
                  message: 'Vui lòng chọn loại căn hộ!'
                }]}
              >
                <Select defaultValue={HOME_TYPE.DORM_HOMESTAY.id}>
                  {
                    TypeOptions.map((item, index) => (
                      <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{span: 24}} md={{span: 12}}>
              <Form.Item
                label="Thành phố"
                name="city_id"
                rules={[{
                  required: true,
                  message: 'Vui lòng chọn thành phố!'
                }]}
              >
                <Select>
                  {
                    cities.map((item, index) => (
                      <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Địa chỉ"
            name="address"
            rules={[{
              required: true,
              message: 'Vui lòng nhập địa chỉ căn hộ!'
            }]}
          >
            <Input/>
          </Form.Item>
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
            }}
          >
            <Col xs={{span: 24}} md={{span: 12}}>
              <Form.Item
                label="Kinh độ"
                name="lng"
              >
                <Input/>
              </Form.Item>
            </Col>
            <Col xs={{span: 24}} md={{span: 12}}>
              <Form.Item
                label="Vĩ độ"
                name="lat"
              >
                <Input/>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="amenities"
            label="Tiện nghi"
          >
            <Amenities/>
          </Form.Item>
        </Col>
        <Col xs={{span: 24}} md={{span: 12}}>
          <Form.Item
            name="media"
            label="Avatar"
          >
            <UploadPicture
              multiple={false}
              useType="avatar"
            />
          </Form.Item>
          <Form.Item
            name="media"
            label="Ảnh khác"
          >
            <UploadPicture
              multiple={true}
              useType="picture"
              maxFiles={10}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={{span: 24}} md={{span: 24}}>
          <Form.Item
            label="Mô tả chi tiết căn hộ"
            name="full_description"
          >
            <RichTextEditor/>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default React.memo(BasicInfo);
