import React from 'react';
import {publicUrl} from '../../common/helpers';
import './AuthLayout.scss';

const blankImage = publicUrl('/assets/images/slide/blank.png');

const slideItems = [
  {
    image: publicUrl('/assets/images/slide/designer.svg'),
    caption: '',
    bgColor: '#0064b7',
  },
];

class AuthLayout extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 0,
    };
    this.sliderRef = React.createRef();
  }

  componentDidMount() {
    this.timer = setInterval(this.changeSlide, 3500);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  changeSlide = () => {
    setTimeout(() => {
      this.setState(prevState => {
        return {
          slideIndex: (prevState.slideIndex + 1) % slideItems.length,
        };
      });
    }, 1000);
  };

  render() {
    const {children} = this.props;
    const {slideIndex} = this.state;
    const currentSlideItem = slideItems[slideIndex];
    return (
      <div className="auth-layout" style={{backgroundColor: currentSlideItem.bgColor}}>
        <div className="auth-container">
          <div className="auth-slider" ref={this.sliderRef}>
            <div className="image-wrapper">
              <img src={blankImage} style={{backgroundImage: `url(${currentSlideItem.image})`}} alt="Banner"/>
            </div>
            {/*<div className="caption">&quot;{currentSlideItem.caption}&quot;</div>*/}
          </div>
          <div className="auth-form">
            {children}
          </div>
        </div>
        <p className="copy-right">{process.env.REACT_APP_NAME} ©2020. Developed by <a href="https://vinaetech.com" target="_blank" rel="noopener noreferrer">VinaETech</a>.</p>
        {
          slideItems.map((item, index) => (
            <img src={item.image} key={index} style={{display: 'none'}} alt="Banner"/>
          ))
        }
      </div>
    );
  }
}

export default AuthLayout;
