import React from 'react';
import {Redirect} from 'react-router-dom';

class Dashboard extends React.PureComponent {
  render() {
    return (
      <Redirect to="/articles"/>
    );
  }
}

export default Dashboard;
