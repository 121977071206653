export const ALLOWED_UPLOAD_FILE_EXTENSIONS = [
  'inv',
  'xml',
  'pdf',
  'doc',
  'docx',
  'zip',
  'jpg',
  'png',
  'gif',
  'txt',
  'htm',
  'html',
  'xslt',
];

export const ARTICLE_TYPE = {
  STORY: {
    id: 1,
    name: 'Stories',
  },
  NEWSLETTER: {
    id: 2,
    name: 'Newsletter',
  },
  STATIC_PAGE: {
    id: 3,
    name: 'Static page',
  },
};

export const IMAGE_EXTENSIONS = ['jpe', 'jpg', 'jpeg', 'png', 'gif'];

export const HOME_TYPE = {
  DORM_HOMESTAY: {
    id: 1,
    name: 'Dorm Homestay'
  },
  PRIVATE_HOMESTAY: {
    id: 2,
    name: 'Private Homestay'
  },
};

export const HOME_TYPE_BY_ID = Object.values(HOME_TYPE).reduce((result, item) => ({
  ...result,
  [item.id]: item,
}), {});
