import {call, put, takeEvery} from 'redux-saga/effects';
import {GET_AMENITIES_AC, getAmenitiesSuccessAC} from '../../actions/home';
import {getAmenitiesAPI} from '../../../api/homes';

function* getAmenities(action) {
  const params = {};
  if (action.language_code) {
    params.language_code = action.language_code;
  }
  const {data} = yield call(getAmenitiesAPI, params);
  yield put(getAmenitiesSuccessAC(data));
}

export default function* () {
  yield takeEvery(GET_AMENITIES_AC, getAmenities);
}
